// import 'react-app-polyfill/ie11';
// import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import store from './store';
import './Common/i18n';
import { DetectRoot } from './DetectRoot';
import { http } from './Common/services/api.services';
import { logout } from './Auth/redux/actions/auth.actions';
import { toggleModal, miniWebApp } from './Common/redux/common.actions';
import { NETWORK_ERROR } from './Common/Modals/modal.contants';
import { createCookie } from './Common/utils/cookie-helper';

http.interceptors.response.use(
  response => response,
  error => {
    if (error.response?.status === 401) {
      localStorage.removeItem('token');

      const hostArr = window.location.hostname.split('.');
      const domain = hostArr.slice(Math.max(hostArr.length - 2, 1)).join('.');
      createCookie({
        name: 'logged_in',
        value: 0,
        days: 30,
        domain: `.${domain}`,
      });
      store.dispatch(logout());
    } else if (error.message === 'Network Error') {
      store.dispatch(toggleModal(NETWORK_ERROR));
    }
    // else if (error.response?.status === 500) {
    //   store.dispatch(toggleModal(ERROR_MODAL));
    // }
    throw error;
  },
);

if (window.Telegram.WebApp) {
  const telegram = window.Telegram.WebApp;
  telegram.ready();
  if (telegram.initData && telegram.initDataUnsafe?.user) {
    const userInfo = telegram.initDataUnsafe?.user;
    store.dispatch(miniWebApp(telegram.initData, userInfo, telegram));
    sessionStorage.setItem('telegramInitData', telegram.initData);
    sessionStorage.setItem('telegramUser', JSON.stringify(userInfo));
  } else {
    const storedInitData = sessionStorage.getItem('telegramInitData');
    let storedUserData = sessionStorage.getItem('telegramUser');
    storedUserData = storedUserData ? JSON.parse(storedUserData): null;
    if (storedInitData && storedUserData) {
      store.dispatch(miniWebApp(storedInitData, storedUserData, telegram));
    } else {
      console.error('No initData available from Telegram or localStorage.');
    }
  }

}

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <DetectRoot />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root'),
);
